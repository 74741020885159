import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from "react-datepicker"

export default class FormikDate extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    onSetDateOfBirth: PropTypes.func,
    dateOfBirth: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ])
  }

  handleChange = date => {
    const { onChange, onSetDateOfBirth } = this.props

    onSetDateOfBirth(date)
    onChange(date)
  }

  handleKeyDown = event => {
    if (event.keyCode === 8 || event.keyCode === 189) return

    if (event.target.value.length === 2 || event.target.value.length === 5) {
      event.target.value = event.target.value + '-'
    }
  }

  render () {
    const { dateOfBirth } = this.props
    return (
      <DatePicker
        selected={dateOfBirth}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        dateFormat='dd-MM-yyyy'
        placeholderText='dd-mm-yyyy'
        openToDate={dateOfBirth || new Date()}
        minDate={new Date('1900-01-01')}
        maxDate={new Date()}
        className='c-input'
        name='birthday'
        strictParsing
      />
    )
  }
}
