import React from 'react'
import PropTypes from 'prop-types'

const Review = ({quote, author, company}) => (
  <div className="c-review">
    <h2 className="c-review__text">
      "{quote}"
    </h2>
    <p className="c-review__person">
      - {author}{company && `, ${company}`}
    </p>
  </div>
)

Review.propTypes = {
  quote: PropTypes.string,
  author: PropTypes.string,
  company: PropTypes.string
}

export default Review
