import React from 'react'
import PropTypes from 'prop-types'
import Classnames from 'classnames'

class FormStep extends React.Component {
  static propTypes = {
    step: PropTypes.string,
    totalSteps: PropTypes.number,
    title: PropTypes.string,
    isActive: PropTypes.bool,
    children: PropTypes.node,
    stepTitle: PropTypes.string
  }

  getStepTitle = () => {
    const { stepTitle, step, totalSteps } = this.props
    if (stepTitle) {
      return `Stap ${step}: ${stepTitle}`
    }

    return `Stap ${step} van ${totalSteps}`
  }

  render() {
    const {title, children, isActive} = this.props

    return (
      <div className={Classnames('c-form-step', {'c-form-step--is-active': isActive})}>
        <p className='c-form-step__step'>{this.getStepTitle()}</p>
        <p className='c-form-step__title'>{title}</p>
        <div className='c-form-step__content'>
          {children}
        </div>
      </div>
    )
  }
}

export default FormStep
