import React from 'react'
import fetchKvk from "../../api/kvk";
import SuggestionsList from '../suggestions-list'
import PropTypes from 'prop-types'
import Input from "../input";

class KvkField extends React.Component {
  static propTypes = {
    ...Input.propTypes,
    onClickSuggestion: PropTypes.func
  }

  state = {
    results: [],
    suggestions: [],
    error: null
  }

  ref = React.createRef()

  onFocus = (event) => {
    this.onChange(event)
  }

  onBlur = (e) => {
    this._clearState()
    this.props.onBlur(e)
  }

  onChange = (e) => {
    this.props.onChange(e)
    const {target: {value}} = e
    this.timeout && clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this._requestApi(value), 400)
  }

  onClickSuggestion = (index) => {
    const {suggestions} = this.state
    const suggestion = suggestions[index]
    if (!suggestion) {
      console.warn('KvkField suggestion not found')
      return
    }
    this.props.onClickSuggestion(suggestion)
  }

  handleClickOutside = (e) => {
    if (this.ref && !this.ref.current.contains(event.target)) {
      this._clearState()
    }
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  render() {
    const {error, suggestions} = this.state
    const {id, name, placeholder, value} = this.props
    return (
      <div className="c-kvk-field" ref={this.ref}>
        <Input
          {...{id, name, placeholder, value}}
          onChange={this.onChange}
          onBlur={this.onBlur}
          autoComplete="none"
        />
        <SuggestionsList suggestions={suggestions} onClick={this.onClickSuggestion}>
          {Boolean(error) && (
            <div className="mage-error">
              {error}
            </div>
          )}
        </SuggestionsList>
      </div>
    )
  }

  _requestApi = async function _requestApi(q) {
    const suggestions = await fetchKvk(q)
    this.setState({
      suggestions
    })
  }

  _clearState = () => {
    setTimeout(() => {
      this.setState({
        results: [],
        suggestions: []
      })
    }, 200)
  }
}

export default KvkField
