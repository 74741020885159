import React from 'react'
import Classnames from 'classnames'

export default function FormikRadio ({field: { name, value, onChange, onBlur }, id, label, className, ...props}) {
  return (
    <div className="c-radio-button">
      <input
        name={name}
        id={id}
        type="radio"
        value={id}
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        className="c-radio-button__input"
        {...props}
      />
      <label
        htmlFor={id}
        className={Classnames("c-radio-button__label", {"c-radio-button__label--checked": id === value})}>
        {label}
      </label>
    </div>
  )
}
