import React from 'react'
import Search from 'components/search'

import PropTypes from 'prop-types'
import Section from 'components/section'

const SearchSection = ({algoliaAppId, algoliaIndice, algoliaApiKey, algoliaSearchUrl, algoliaFacets}) => (
  <Section type='secondary' extraClass='c-search-section' noSkew hasBigNegativeMarginBottom>
    <div className='c-search-section__content'>
      <p className='c-search-section__title'>Zakelijk leasen? <br /> Bekijk het aanbod.</p>
      <img src='/media/assets/female-pointing-down.png' alt='woman search image' className='c-search-section__image' />
      <Search
        algoliaAppId={algoliaAppId}
        algoliaIndice={algoliaIndice}
        algoliaApiKey={algoliaApiKey}
        algoliaSearchUrl={algoliaSearchUrl}
        algoliaFacets={algoliaFacets}
      />
    </div>
  </Section>
)

SearchSection.propTypes = {
  algoliaAppId: PropTypes.string,
  algoliaIndice: PropTypes.string,
  algoliaApiKey: PropTypes.string,
  algoliaSearchUrl: PropTypes.string,
  algoliaFacets: PropTypes.object
}



export default SearchSection