"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _createConnector = _interopRequireDefault(require("../core/createConnector"));

/**
 * connectCurrentRefinements connector provides the logic to build a widget that will
 * give the user the ability to remove all or some of the filters that were
 * set.
 * @name connectCurrentRefinements
 * @kind connector
 * @propType {function} [transformItems] - Function to modify the items being displayed, e.g. for filtering or sorting them. Takes an items as parameter and expects it back in return.
 * @propType {function} [clearsQuery=false] - Pass true to also clear the search query
 * @providedPropType {function} refine - a function to remove a single filter
 * @providedPropType {array.<{label: string, attribute: string, currentRefinement: string || object, items: array, value: function}>} items - all the filters, the `value` is to pass to the `refine` function for removing all currentrefinements, `label` is for the display. When existing several refinements for the same atribute name, then you get a nested `items` object that contains a `label` and a `value` function to use to remove a single filter. `attribute` and `currentRefinement` are metadata containing row values.
 * @providedPropType {string} query - the search query
 */
var _default = (0, _createConnector.default)({
  displayName: 'AlgoliaCurrentRefinements',
  propTypes: {
    transformItems: _propTypes.default.func
  },
  getProvidedProps: function getProvidedProps(props, searchState, searchResults, metadata) {
    var items = metadata.reduce(function (res, meta) {
      if (typeof meta.items !== 'undefined') {
        if (!props.clearsQuery && meta.id === 'query') {
          return res;
        } else {
          if (props.clearsQuery && meta.id === 'query' && meta.items[0].currentRefinement === '') {
            return res;
          }

          return res.concat(meta.items.map(function (item) {
            return (0, _objectSpread2.default)({}, item, {
              id: meta.id,
              index: meta.index
            });
          }));
        }
      }

      return res;
    }, []);
    var transformedItems = props.transformItems ? props.transformItems(items) : items;
    return {
      items: transformedItems,
      canRefine: transformedItems.length > 0
    };
  },
  refine: function refine(props, searchState, items) {
    // `value` corresponds to our internal clear function computed in each connector metadata.
    var refinementsToClear = items instanceof Array ? items.map(function (item) {
      return item.value;
    }) : [items];
    return refinementsToClear.reduce(function (res, clear) {
      return clear(res);
    }, searchState);
  }
});

exports.default = _default;