import React from 'react'
import PropTypes from 'prop-types'

class Checkbox extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    required: PropTypes.bool
  }

  state = {
    prevProps: {},
    checked: false
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const {prevProps} = prevState
    const nextState = {
      ...prevState,
      prevProps: {checked: nextProps.checked}
    }

    if (prevProps.checked !== nextProps.checked) {
      nextState.checked = nextProps.checked
    }

    return nextState
  }

  onChange = () => {
    this.setState({checked: !this.state.checked})
    setTimeout(this.props.onChange, 20)
  }



  render () {
    const {name, id, label, required, errors} = this.props
    return (
      <div className="c-checkbox">
        <input className="c-checkbox__input" type="checkbox" name={name} id={id} checked={this.state.checked ? 'checked' : ''} onChange={this.onChange}/>
        <label className="c-checkbox__label" htmlFor={id}>
          {label} {required && <span className="c-form__required">*</span>}
        </label>
        {errors && <span className="c-form__required">{errors}</span>}
      </div>
    )
  }
}

export default Checkbox
