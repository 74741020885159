import React from 'react'
import PropTypes from 'prop-types'
import Classnames from 'classnames'

class TopBar extends React.Component {
  static propTypes = {
    phoneNumber: PropTypes.string,
    whatsappNumber: PropTypes.string,
    online: PropTypes.bool,
    score: PropTypes.string,
    reviewLink: PropTypes.string,
    numReviews: PropTypes.string,
    onlineFrom: PropTypes.number,
    offlineFrom: PropTypes.number
  }
  static defaultProps = {
    online: false,
    onlineFrom: 900,
    offlineFrom: 1800,
    phoneNumber: '030 - 267 99 93',
    whatsappNumber: '+31625120820',
    reviewLink: 'https://www.feedbackcompany.com/nl-nl/reviews/financiallease-nl/',
    numReviews: '1.655',
    score: '9.5/10'
  }
  state = {
    online: this.props.online,
    score: this.props.score,
    numReviews: this.props.numReviews,
  }

  componentDidMount () {
    // TODO: call feedback company api
    this._checkOnline()
    setInterval(this._checkOnline, 30000) //check every 30 seconds
  }

  _checkOnline = () => {
    const { onlineFrom, offlineFrom } = this.props
    const currentDate = new Date()
    const currentTime = currentDate.getMinutes() + (currentDate.getHours() * 100)
    const online = currentTime >= onlineFrom && currentTime < offlineFrom
    this.setState({ online })
  }

  render () {
    const { phoneNumber, whatsappNumber, reviewLink } = this.props
    const { online, numReviews, score } = this.state
    const phoneNumberClassNames = Classnames('c-top-bar__phone-number-link', 'c-phone-number-link', {
      'is-online': online
    })

    return (
      <div className="c-top-bar-container">
        <div className="container">
          <div className="c-top-bar">
            <div className="c-top-bar__phone-number">
              <a href={`tel: ${phoneNumber}`} className={phoneNumberClassNames}>
                {phoneNumber}
              </a>
            </div>
            <div className="c-top-bar__whatsapp">
              <a href={`https://wa.me/${whatsappNumber}`} target="_blank">
                <img src="/media/assets/whatsapp.png" width="32" height="31" />
              </a>
            </div>
            <div className="c-top-bar__reviews">
              <a href={reviewLink} className="c-top-bar__reviews-link" target="_blank">{numReviews}</a>
              <span className="c-top-bar__reviews-score"> ondernemers beoordelen ons gemiddeld met een {score}</span>
              <img src="/media/assets/feedbackcompany-stars.png" width="99" height="18" className="c-top-bar__reviews-stars" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TopBar
