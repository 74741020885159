import React from 'react'
import PropTypes from 'prop-types'

class FormInput extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    children: PropTypes.node,
    comment: PropTypes.string
  }

  render () {
    const {label, required, children, comment} = this.props

    return (
      <div className="c-form-input row">
        <p className="c-form-input__label col-md-3">{label} {required && <span className="c-form__required">*</span>}</p>
        <div className="c-form-input__input col-md-4">
          {children}
        </div>
        {comment && <div className="c-form-input__comment col-md-4"> {comment} </div>}
      </div>
    )
  }
}

export default FormInput
