import React, {Component} from 'react'
import FormInput from "../form-input"
import KvkField from "../kvk-field";

export default class FormikKvk extends Component {
  state = {
    companyName: '',
    kvkNumber: ''
  }

  onClickSuggestion = ({companyName, kvkNumber}) => {
    this.props.setFieldTouched('companyName', true)
    this.props.setFieldTouched('kvkNumber', true)
    this.props.setFieldValue('companyName', companyName)
    this.props.setFieldValue('kvkNumber', kvkNumber)
    this.setState({
      companyName,
      kvkNumber
    })
  }

  onChange = ({target: {name, value}}) => {
    this.props.setFieldValue(name, value)
    this.setState({
      [name]: value
    })
  }

  onBlurField = (e) => {
    this.props.setFieldTouched(e.target.name, true)
  }

  render() {
    const {errors, touched} = this.props
    return (
      <React.Fragment>
        <FormInput
          label='Bedrijfsnaam'
          required
          comment="Bij het kiezen van je bedrijfsnaam wordt automatisch je KVK-nummer ingevuld"
        >
          <KvkField
            name='companyName'
            id='companyName'
            placeholder='Bedrijfsnaam'
            value={this.state.companyName}
            onBlur={this.onBlurField}
            onChange={this.onChange}
            onClickSuggestion={this.onClickSuggestion}
          />
          {errors['companyName'] && touched['companyName'] &&
          <span className="c-form__required">{errors['companyName']}</span>}
        </FormInput>
        <FormInput label='KVK-nummer' required>
          <KvkField
            name='kvkNumber'
            id='kvkNumber'
            placeholder='KVK-nummer'
            value={this.state.kvkNumber}
            onBlur={this.onBlurField}
            onChange={this.onChange}
            onClickSuggestion={this.onClickSuggestion}
          />
          {errors['kvkNumber'] && touched['kvkNumber'] &&
          <span className="c-form__required">{errors['kvkNumber']}</span>}
        </FormInput>
      </React.Fragment>
    )
  }
}
