import React from 'react'
import PropTypes from 'prop-types'

import Section from '../../components/section'
import HelpBlock from '../../components/help-block'

const HelpSection = ({blockTitle, blockSubtitle, block1, block2, block3}) => (
  <Section type='tertiary' hasQuote hasBigNegativeMarginBottom>
    <div className="c-quote">
      <img className="c-quote__image" src='/media/assets/male-headphones.png' alt='quote image man'/>
      <div className="c-quote__titles">
        <h2 className="c-quote__title">{blockTitle}</h2>
        <h3 className="c-quote__subtitle">{blockSubtitle}</h3>
      </div>
    </div>
    <div className="row">
      <div id="owl-showarticles">
        <div className="col-sm-4">
          <HelpBlock
            icon={`flicon-${block1.icon}`}
            link={block1.buttonText}
            text={block1.text}
            title={block1.title}
            url={block1.buttonLink}/>
        </div>
        <div className="col-sm-4">
          <HelpBlock
            icon={`flicon-${block2.icon}`}
            link={block2.buttonText}
            text={block2.text}
            title={block2.title}
            url={block2.buttonLink}/>
        </div>
        <div className="col-sm-4">
          <HelpBlock
            icon={`flicon-${block3.icon}`}
            link={block3.buttonText}
            text={block3.text}
            title={block3.title}
            url={block3.buttonLink}/>
        </div>
      </div>
    </div>
  </Section>
)

HelpSection.propTypes = {
  blockTitle: PropTypes.string,
  blockSubtitle:PropTypes.string,
  block1: PropTypes.object,
  block2: PropTypes.object,
  block3: PropTypes.object,
}

export default HelpSection