import React from 'react';
import Classnames from 'classnames';
import Proptypes from 'prop-types';
import InfoCircle from '../info-circle'

const LabeledInput = ({
  label,
  comment,
  name,
  type,
  pattern,
  onChange,
  labelClass,
  inputClass,
  value,
  defaultValue,
  readOnly,
  children
}) => {
  return (
    <React.Fragment>
      <label className={Classnames('t-bold', labelClass)}>
        {label}
        {Boolean(comment) && (
          <InfoCircle icon="info-circle" text={comment}/>
        )}
      </label>
      {type === 'select'
        ?
        <div className="c-calculation-block__select-container">
        <select
          name={name}
          onChange={onChange}
          className={Classnames('c-calculation-block__select', inputClass)}
          defaultValue={defaultValue}>
          {children}
        </select>
        </div>
        :
        <div className={Classnames('c-calculation-block__input', inputClass, {'c-calculation-block__input--disabled': readOnly})}>
          {readOnly ? (
            <p className='c-calculation-block__input-text'>{value}</p>
          ) : (
            <React.Fragment>
              <span className="c-calculation-block__currency">€</span>
              <input
                name={name}
                type={type}
                value={value}
                onChange={onChange}
                pattern={pattern}
              />
            </React.Fragment>
          )}

        </div>
      }
    </React.Fragment>
  );
};

LabeledInput.propTypes = {
  label: Proptypes.string,
  comment: Proptypes.string,
  name: Proptypes.string,
  type: Proptypes.string,
  onChange: Proptypes.func,
  labelClass: Proptypes.string,
  inputClass: Proptypes.string,
  defaultValue: Proptypes.any,
  readOnly: Proptypes.bool,
  value: Proptypes.oneOfType([Proptypes.number, Proptypes.string])
};
LabeledInput.defaultProps = {
  label: '',
  name: '',
  type: '',
  onChange: () => {},
  labelClass: '',
  inputClass: '',
  value: 0,
  readOnly: false
};

export default LabeledInput;
