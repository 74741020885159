import React from 'react'
import PropTypes from 'prop-types'

class SuggestionsList extends React.Component {
  static propTypes = {
    suggestions: PropTypes.arrayOf(
      PropTypes.shape({
        kvkNumber: PropTypes.string,
        companyName: PropTypes.string,
        street: PropTypes.string,
        houseNumber: PropTypes.string,
        city: PropTypes.string
      })
    ),
    onClick: PropTypes.func,
    children: PropTypes.node
  }
  static defaultProps = {
    suggestions: [],
    onClick: () => {
    }
  }

  render() {
    const {suggestions, children} = this.props

    return (
      <div className="c-suggestions-list">
        {Boolean(children) && (
          <div className="c-suggestions-list__content">
            {children}
          </div>
        )}
        {Boolean(suggestions.length) && (
          <div className="c-suggestions-list__items">
            {suggestions.map(this._renderSuggestion)}
          </div>
        )}
      </div>
    )
  }

  _renderSuggestion = ({kvkNumber, companyName, street, houseNumber, city}, idx) => {
    const {onClick} = this.props
    return (
      <div key={idx} className="c-suggestions-list__item" onClick={() => onClick(idx)}>
        <div className="c-kvk-field__suggestion">
          <div className="c-kvk-field__suggestion-title">
            {companyName}
          </div>
          <div className="c-kvk-field__suggestion-subtitle">
            {street} {houseNumber}, {city} ({kvkNumber})
          </div>
        </div>
      </div>
    )
  }
}

export default SuggestionsList
