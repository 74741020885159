import ipTrace from 'api/ip-trace'
import { EXCLUDED_IPS } from 'utils/constants'

export const kwToHP = (input) => Math.round(input * 1.361)
export const hpToKW = (input) => Math.round(input * 0.734754)

export const isCookieSet = (cookieName) => {
  if (document.cookie.split(';').filter((item) => item.trim().startsWith(cookieName)).length) {
    return true
  }

  return false
}

export const setCookie = (cookieName, value, maxAge, path = '/') => {
  document.cookie = `${cookieName}=${value}; max-age=${maxAge}; path=${path};`
}

export const getCookieValue = (cookieName) => {
  const cookie = document.cookie
    .split(';')
    .find((item) => item.trim().includes(cookieName))

  if (!cookie) return null

  const cookieArray = cookie.split('=')

  return cookieArray[cookieArray.length - 1]
}

export const getIpAddress = async () => {
  const data = await ipTrace()

  return data
    .split('\n')
    .map(item => item.split('='))
    .find(item => item[0] === 'ip')[1]
}

export const isIpExcluded = ipAddress => EXCLUDED_IPS.includes(ipAddress)

export const formatDate = (date) => {
  if (typeof date === 'string' && date.length > 0) {
    const dateParts = date.split('-')
    return [ dateParts[1], dateParts[0], dateParts[2] ].join('-')
  } else if (date instanceof Date) {
    const day = ("0" + date.getDate()).slice(-2)
    const month = ("0" + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()
    return month + '-' + day + '-' + year
  }
  return void (0)
}
