import React from 'react'

export default function FormikInput ({field, form: {touched, errors}, ...props}) {
  return (
    <div className="c-input-container">
      <input
        className="c-input"
        id={field.name}
        type={props.type ? props.type : "text"}
        name={field.name}
        autoComplete="off"
        {...props}
        {...field}
      />
      {errors[field.name] && touched[field.name] && <span className="c-form__required">{errors[field.name]}</span>}
    </div>
  )
}
