import React from 'react'
import PropTypes from 'prop-types'

const UspBlock = ({image, children}) => (
  <div className='c-usp-block'>
    <div className="c-usp-block__image-container">
      <img src={image} alt='' className='c-usp-block__image'/>
    </div>
    <div className="c-usp-block__skew">
      <svg preserveAspectRatio="none" viewBox="0 0 100 100">
        <polygon points="100,100 0,0 100,0"></polygon>
      </svg>
    </div>
    <div className='c-usp-block__content'>
      {children}
    </div>
  </div>
)

UspBlock.propTypes = {
  image: PropTypes.string,
  children: PropTypes.node
}

export default UspBlock