import React from 'react'
import PropTypes from 'prop-types'
import Classnames from 'classnames'

class Link extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    href: PropTypes.string,
    icon: PropTypes.string,
    hasBorder: PropTypes.bool
  }

  static defaultProps = {}
  state = {}

  render () {
    const { children, href, icon, hasBorder} = this.props
    const iconClass = Classnames('c-link__icon flicon-' + icon)
    const className = Classnames('c-link', {
      'c-link--icon': icon,
      'c-link--has-border': hasBorder
    })
    return (
      <a className={className} href={href}>
        {icon && <i className={iconClass}></i>}
        {children}
      </a>
    )
  }
}

export default Link
