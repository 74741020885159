import React from 'react'
import {useField} from 'formik'

export default function FormikCheckbox(props) {
  const [field, meta] = useField(props.field.name)
  const onClick = props.onChange;

  return (
    <div className='c-checkbox'>
      <input {...field} type='checkbox' className="c-checkbox__input" value={field.value}/>
      <label className="c-checkbox__label" id={field.name} onClick={onClick}>
        <span dangerouslySetInnerHTML={{ __html: props.label }} />{props.isRequired && (<span className="c-form__required">*</span>)}
      </label>
      {meta.error && meta.touched && <span className="c-form__required">{meta.error}</span>}
    </div>
  )
}