import React from 'react'
import PropTypes from 'prop-types'
import Classnames from 'classnames'

class Input extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    icon: PropTypes.string,
    iconLeft: PropTypes.bool,
    iconRight: PropTypes.bool,
    isDisabled: PropTypes.bool
  }
  static defaultProps = {}
  state = {}

  render () {
    const {
      type,
      name,
      value,
      placeholder,
      onBlur,
      onFocus,
      onChange,
      autoComplete,
      icon,
      iconLeft,
      iconRight,
      isDisabled
    } = this.props

    const id = this.props.id || name
    const iconClass = Classnames('c-input__icon flicon-' + icon, {
      'c-input__icon--right': iconRight,
      'c-input__icon--left': iconLeft
    })

    return (
      <div className="c-input-container">
        {icon && <i className={iconClass}></i> }
        <input
          type={type}
          name={name}
          id={id}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          autoComplete={autoComplete}
          disabled={isDisabled}
          className={Classnames('c-input', {
            'c-input--icon-left': iconLeft,
            'c-input--icon-right': iconRight
          })}
        />
      </div>
    )
  }
}

export default Input
