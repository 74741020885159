"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _connectQueryRules = _interopRequireDefault(require("../connectors/connectQueryRules"));

var _default = (0, _connectQueryRules.default)(function QueryRuleContext() {
  return null;
});

exports.default = _default;