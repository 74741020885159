import React from 'react'
import PropTypes from 'prop-types'
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"
import memoizeOne from "memoize-one"

import { COOKIES } from 'utils/constants'
import { setCookie, getIpAddress, isIpExcluded } from 'utils/helpers'

import withPostForm from 'containers/with-post-form'

import FormStep from "../components/form-step"
import FormInput from "../components/form-input"
import Checkbox from "../components/checkbox"

import FormikInput from "../components/formik-input";
import FormikRadio from "../components/formik-radio";
import FormikTextArea from "../components/formik-textarea"
import FormikCheckbox from "../components/formik-checkbox";
import FormikKvk from "../components/formik-kvk";
import FormikDate from "../components/formik-date";

class GetAQuote extends React.Component {
  static propTypes = {
    hiddenFields: PropTypes.object,
    endpoint: PropTypes.string,
    successUrl: PropTypes.string,
    postStatus: PropTypes.string,
    postForm: PropTypes.func,
    errorMessage: PropTypes.string
  }

  state = {
    currentStep: 1,
    totalSteps: 2,
    dateOfBirth: '',
    errorMessage: '',
    ipAddress: ''
  }

  stepOneValidation = Yup.object().shape({
    companyName: Yup.string()
      .required("Dit veld is verplicht."),
    kvkNumber: Yup.string()
      .required("Dit veld is verplicht.")
      .min(8, "Vul minimaal 8 karakters in"),
    phoneNumber: Yup.string()
      .required('Dit veld is verplicht.')
      .min(8, "Voer een geldig telefoonnummer in")
      .max(13, "Voer een geldig telefoonnummer in"),
    emailAddress: Yup.string()
      .email("Voer een geldig e-mailadres in")
      .matches(/^[A-Za-z0-9!#$%&.'*/=?^_+-`{|}~]+$/, "Het e-mailadres bevat karakters die niet toegestaan zijn")
      .required("Dit veld is verplicht.")
  })
  stepTwoValidation = Yup.object().shape({
    salutation: Yup.string()
      .required("Dit veld is verplicht,"),
    initials: Yup.string()
      .required("Dit veld is verplicht,"),
    firstName: Yup.string()
      .required("Dit veld is verplicht,"),
    lastName: Yup.string()
      .required("Dit veld is verplicht,"),
    dateOfBirth: Yup.string()
      .required("Dit veld is verplicht,"),
    privacyOptIn: Yup.bool()
      .test('privacyOptIn', 'Accepteer dit veld om verder te kunnen gaan.', value => value === true)
  })
  formValidation = [this.stepOneValidation, this.stepTwoValidation]

  renderNavigationButtons = ({state, props}) => {
    const back = state.currentStep !== 1
    const isLastStep = state.currentStep === state.totalSteps

    return (
      <div className="row">
        <div className="col-12 col-md-7 u-flex u-space-between">
          {back && (
            <button
              type="button"
              className='c-link c-link--icon'
              onClick={this.onClickPrevButton}
            >
              <i className='c-link__icon flicon-arrow-left'></i> Vorige
            </button>
          )}
          {isLastStep ? (
            <button
              type="button"
              className='c-button c-button--primary c-button--width-auto'
              onClick={() => this.handleSubmit(props)}
            >
              Verzenden
            </button>
          ) : (
            <button
              type="button"
              className='c-button c-button--primary c-button--icon-right c-button--width-auto'
              onClick={() => this.onClickNextButton(props)}
            >
              Volgende <i className='c-button__icon c-button__icon--right flicon-arrow-right'></i>
            </button>
          )}
        </div>
        {this.props.errorMessage && (
          <div className="col-12 col-md-7 c-form__required">
            <br />
            {this.props.errorMessage}
          </div>
        )}
      </div>
    )
  }

  validateRequiredFields = async (props) => {
    const invalidFields = await props.validateForm()
    Object.keys(invalidFields).forEach((field) => {
      props.setFieldTouched(field, true)
    })
    return Boolean(!Object.keys(invalidFields).length)
  }

  onClickNextButton = async (props) => {
    const isValid = await this.validateRequiredFields(props)
    return isValid && this.setState({currentStep: this.state.currentStep + 1})
  }

  onClickPrevButton = () => {
    if (this.state.currentStep > 1) {
      this.setState({currentStep: this.state.currentStep - 1})
    }
  }
  handleSubmit = async (props) => {
    const isValid = await this.validateRequiredFields(props)
    if (!isValid) return
    const values = props.values
    const { postForm, endpoint, successUrl } = this.props
    postForm(endpoint, {
      url: values.url,
      hexonClientId: values.hexonClientId,
      sku: values.sku,
      brand: values.brand,
      model: values.model,
      type: values.type,
      licensePlate: values.licensePlate,
      buildYear: values.buildYear,
      leasePrice: values.leasePrice,
      salesPrice: values.salesPrice,
      monthlyPrice: values.monthlyPrice,
      closingPrice: values.closingPrice,
      runTime: values.runTime,
      mileage: values.mileage,
      btwMarge: values.btwMarge,
      fuelType: values.fuelType,
      firstRegistration: values.firstRegistration,
      vehicleType: values.vehicleType,
      domain: values.domain,
      companyName: values.companyName,
      kvkNumber: values.kvkNumber,
      phoneNumber: values.phoneNumber,
      emailAddress: values.emailAddress,
      salutation: values.salutation,
      initials: values.initials,
      firstName: values.firstName,
      infix: values.infix,
      lastName: values.lastName,
      dateOfBirth: values.dateOfBirth,
      comments: values.comments,
      newsletterOptIn: values.newsletterOptIn,
      privacyOptIn: values.privacyOptIn,
      gaConnectorData: typeof gaconnector !== 'undefined' ? gaconnector.getCookieValues() : null
    }, successUrl)
      .then(() => {
        if (isIpExcluded(this.state.ipAddress)) return
        setCookie(COOKIES.QUOTE_IS_BLOCKED, 1, 1800)
      })
      .catch((error) => console.error(error))
  }

  getInitialValues = memoizeOne(() => {
    return {
      companyName: '',
      kvkNumber: '',
      phoneNumber: '',
      emailAddress: '',
      salutation: '',
      initials: '',
      firstName: '',
      infix: '',
      lastName: '',
      dateOfBirth: '',
      comments: '',
      newsletterOptIn: false,
      privacyOptIn: false,
      gaConnectorData: typeof gaconnector !== 'undefined' ? gaconnector.getCookieValues() : null,
      ...this.props
    }
  })

  async componentDidMount () {
    const ipAddress = await getIpAddress()
    this.setState({ ipAddress })
  }

  render() {
    const { totalSteps, currentStep, dateOfBirth } = this.state
    const NavigationButtons = this.renderNavigationButtons

    return (
      <Formik
        initialValues={this.getInitialValues()}
        onSubmit={this.handleSubmit}
        validationSchema={this.formValidation[this.state.currentStep - 1]}
      >
        {(props) => (
          <Form>
            <FormStep
              step='1'
              stepTitle="Vraag een gratis offerte aan"
              totalSteps={totalSteps}
              title='Bedrijfsgegevens'
              isActive={currentStep === 1}
            >
              <FormikKvk {...props} />
              <FormInput label='Telefoonnummer' required={true}>
                <Field name="phoneNumber" component={FormikInput} type="tel" />
              </FormInput>
              <FormInput label='E-mail' required={true}>
                <Field name="emailAddress" component={FormikInput} type="email" />
              </FormInput>
            </FormStep>
            <FormStep
              step='2'
              stepTitle="Afronden gratis offerte"
              totalSteps={totalSteps}
              title='Persoonsgegevens'
              isActive={currentStep === 2}
            >
              <FormInput label='Aanhef' required={true}>
                <Field name='salutation' component={FormikRadio} id='male' label='Dhr.'/>
                <Field name='salutation' component={FormikRadio} id='female' label='Mevr.'/>
              </FormInput>
              <FormInput label='Voorletters' required={true}>
                <Field name='initials' component={FormikInput} />
              </FormInput>
              <FormInput label='Voornaam' required={true}>
                <Field name="firstName" component={FormikInput} />
              </FormInput>
              <FormInput label='Tussenvoegsel'>
                <Field name='infix' component={FormikInput} />
              </FormInput>
              <FormInput label='Achternaam' required={true}>
                <Field name='lastName' component={FormikInput} />
              </FormInput>
              <FormInput label='Geboortedatum' required={true}>
                <FormikDate
                  dateOfBirth={dateOfBirth}
                  onSetDateOfBirth={date => this.setState({dateOfBirth: date})}
                  onChange={formattedDate => props.setFieldValue('dateOfBirth', formattedDate)}
                />
              </FormInput>
              <FormInput label='Opmerkingen'>
                <Field name="comments" component={FormikTextArea} />
              </FormInput>
              <FormInput>
                <Checkbox
                    component={FormikCheckbox}
                    onChange={e => props.setFieldValue('newsletterOptIn', !props.values.newsletterOptIn)}
                    name="newsletterOptIn"
                    id="newsletterOptIn"
                    label="Ja, ik ontvang graag e-mails met hierin aanbiedingen en informatie over Financial Lease"
                />
              </FormInput>
              <FormInput>
                <Field
                  name='privacyOptIn'
                  component={FormikCheckbox}
                  label='Ik ga ermee akkoord dat Financial Lease mijn gegevens gebruikt, in overeenstemming met het privacybeleid'
                  onChange={e => props.setFieldValue('privacyOptIn', !props.values.privacyOptIn)}
                  isRequired={true}
                />
                <a href="/privacyverklaring" className="c-link c-link--with-padding-left" target="_blank">Lees hier het privacybeleid</a>
              </FormInput>
            </FormStep>
            <NavigationButtons
              state={this.state}
              props={props}
            />
          </Form>
        )}
      </Formik>
    )
  }
}

export default withPostForm(GetAQuote)
