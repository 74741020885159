import React from 'react'
import LabeledInput from '../labeled-input'
import {withCalculationProps, Section} from '../calculation'
import Classnames from 'classnames'

class CalculationBlock extends React.Component {

  render() {
    const {monthlyPrice, carSalesPrice, leasePrice, closingPrice, error, loading, isBlocked} = this.props.state;
    const {defaultRunningTimeArray, translatedStrings, getNewMonthlyPrice, isIframe, isSmall, carSalesPriceEditable, buttonTitle, buttonText, buttonLink} = this.props;

    return (
      <div className={Classnames('c-calculation-block', {'c-calculation-block--iframe': isIframe})}>
        {isIframe && <hr className="c-calculation-block__separator"/>}
        {!isIframe && !isSmall && (
          <div className="c-calculation-block__text">
            <div className="h3">Wordt dit jouw auto?</div>
            <p className="c-calculation-block__subtitle">Bereken de leasetermijn</p>
          </div>
        )}
        <input name="monthlyLeasePrice" type="hidden" value={monthlyPrice}/>
        <Section>
          <LabeledInput
            label={translatedStrings.salesPrice}
            comment={translatedStrings.salesPriceComment}
            labelClass="c-calculation-block__label"
            name="carSalesPrice"
            type="number"
            pattern="\d*"
            readOnly={!carSalesPriceEditable}
            value={carSalesPrice}
            onChange={getNewMonthlyPrice}
          />
        </Section>
        <Section>
          <LabeledInput
            label={translatedStrings.leasePrice}
            comment={translatedStrings.leasePriceComment}
            labelClass="c-calculation-block__label"
            name="leasePrice"
            type="number"
            pattern="\d*"
            value={leasePrice}
            onChange={getNewMonthlyPrice}
          />
        </Section>
        <Section>
          <LabeledInput
            label={translatedStrings.closingPrice}
            comment={translatedStrings.closingPriceComment}
            labelClass="c-calculation-block__label"
            name="closingPrice"
            type="number"
            pattern="\d*"
            value={closingPrice}
            onChange={getNewMonthlyPrice}
          />
        </Section>
        <Section>
          <LabeledInput
            label={translatedStrings.runTime}
            comment={translatedStrings.runTimeComment}
            labelClass="c-calculation-block__label"
            name="runTime"
            type="select"
            value={leasePrice}
            onChange={getNewMonthlyPrice}
            defaultValue={defaultRunningTimeArray[defaultRunningTimeArray.length - 1]}
          >
            {defaultRunningTimeArray.map((runTime, index) => (
              <option key={runTime} value={runTime}>{runTime} maanden</option>
            ))}
          </LabeledInput>
        </Section>
        {!error ? (
          <p
            className={Classnames("c-calculation-block__calculation", {"c-calculation-block__calculation--is-small": isSmall})}>
            {translatedStrings.leaseTerm}
            <span className="c-calculation-block__calculation-price">{
              loading
                ? <img src="/media/assets/loading.svg" className="c-calculation-block__loader"/>
                : `€ ${monthlyPrice} p/m`}
            </span>
          </p>
        ) : (
          <p className="c-calculation-block__calculation c-calculation-block__calculation--error t-bold">
            {error}
          </p>
        )}
        {buttonTitle && buttonLink && buttonText && (
          <a href={`${buttonLink}`}
             title={buttonTitle}
             className={Classnames('c-button c-button--primary c-button--icon-right c-calculation-block__button addCurrentConfig', {
               'c-button--is-disabled': error || isBlocked
             })}>
            <i className="c-button__icon flicon-arrow-right c-button__icon--right"></i>
            {buttonText}
          </a>
        )}
      </div>
    )
  }
}

const CalculationBlockwithProps = withCalculationProps(CalculationBlock);

export default CalculationBlockwithProps;
