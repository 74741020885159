import React from 'react'

class FeedbackCompany extends React.Component {
  render () {
    const {users, grade} = this.props
    return (
      <div className='c-fbcompany'>
        <p className='c-fbcompany__content'><a href="https://www.feedbackcompany.com/nl-nl/reviews/financiallease-nl/"><strong className='c-fbcompany__users'>{users}</strong></a> ondernemers beoordelen FinancialLease.nl gemiddeld met een</p>
        <div className="c-fbcompany__grades">
          <div className='c-fbcompany__grade'>
            <h2>{grade}</h2>
            <h4>&nbsp;/10</h4>
          </div>
          <div className='c-fbcompany__stars'>
            <i className='flicon-star'/>
            <i className='flicon-star'/>
            <i className='flicon-star'/>
            <i className='flicon-star'/>
            <i className='flicon-star'/>
          </div>
        </div>
      </div>
    )
  }
}

export default FeedbackCompany
