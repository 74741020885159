import React from 'react'
import Classnames from 'classnames'
import Proptypes from 'prop-types'

class Block extends React.Component {
  static propTypes = {
    direction: Proptypes.string.isRequired,
    image: Proptypes.string,
    hasShadow: Proptypes.bool,
    hasFooterBorder: Proptypes.bool,
    hasHeader: Proptypes.bool,
    headerTitle: Proptypes.string,
    buttonText: Proptypes.string,
    buttonLink: Proptypes.string,
    buttonSmall: Proptypes.bool,
    optionalFooterContext: Proptypes.node,
    buttonIsPrimary: Proptypes.bool,
    children: Proptypes.node,
    extraBlockClasses: Proptypes.string,
    isSummary: Proptypes.bool,
    price: Proptypes.number,
    amountOfViews: Proptypes.number,
  }

  render () {
    const {
      direction,
      image,
      hasShadow,
      hasFooterBorder,
      hasHeader,
      headerTitle,
      buttonText,
      buttonLink,
      optionalFooterContext,
      buttonIsPrimary,
      children,
      isSummary,
      price,
      amountOfViews} = this.props

    const classNames = Classnames(
      'c-block',
      `c-block--${direction}`,
      {'c-block--has-shadow': hasShadow},
      {'c-block--is-summary': isSummary}
      )

    return (
      <div className={classNames}>
        {hasHeader && (
          <div className='c-block__header'>
            <h2 className='c-block__header-title'>{headerTitle}</h2>
          </div>
        )}
        <div className='c-block__content'>
          {image && (
            <img src={image} alt='' className={Classnames('c-block__image', `c-block__image--${direction}`, {'c-block__image--summary': isSummary})} />
          )}
          <div className={Classnames('c-block__content-text', `c-block__content--${direction}`)}>
            {amountOfViews && (
              <p className='c-block__content-views'><span>{amountOfViews}</span> keer bekeken</p>
            )}
            {children}
          </div>
        </div>
        {price && (
          <p className='t-price t-price--large t-extra-bold'>€ {price} p/m</p>
        )}
        {(optionalFooterContext || buttonLink) && (
          <div className={Classnames('c-block__footer', {'c-block__footer--has-border': hasFooterBorder})}>
            {optionalFooterContext}
            {buttonLink && (
              <a className={Classnames('c-button c-button--skewed', {'c-button--primary': buttonIsPrimary, 'c-button--no-text': !buttonText})} href={buttonLink}>
                {buttonText}
                <i className='flicon-arrow-right' />
              </a>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default Block
