import React from 'react'

export default function FormikTextArea ({field, form: {touched, errors}, ...props}) {
  return (
    <div className="c-textarea-container">
      <textarea
        className="c-textarea"
        rows="4"
        {...field}
        {...props}
        />
      {errors[field.name] && touched[field.name] && <span className="c-form__required">{errors[field.name]}</span>}
    </div>
  )
}