import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from '../button'

class ProductTile extends React.Component {
  static defaultProps = {}
  static propTypes = {
    isLarge: PropTypes.bool,
    imgUrl: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    leasePrice: PropTypes.number,
    mileage: PropTypes.string,
    amountPk: PropTypes.number,
    constructionDate: PropTypes.string,
    purchasePrice: PropTypes.string,
    fuel: PropTypes.string,
    transmission: PropTypes.string,
    location: PropTypes.string
  }
  state = {}

  render () {
    const {
      isLarge,
      imgUrl,
      title,
      subtitle,
      leasePrice,
      mileage,
      amountPk,
      constructionDate,
      purchasePrice,
      fuel,
      transmission,
      location,
      link
    } = this.props

    const amountKw = Math.floor(amountPk * 0.734)

    return (
      <div className={classNames('c-product-tile', {
        'c-product-tile--large': isLarge
      })}>
        <a href={link} className="c-product-tile__header">
          <img className="c-product-tile__image" src={imgUrl} alt={title} />
        </a>
        <div className="c-product-tile__body">
          <a href={link} className="c-product-tile__body-header">
            <h3 className="c-product-tile__title">{title}</h3>
            <h4 className="c-product-tile__subtitle">{subtitle}</h4>
          </a>
          <table className="c-product-tile__specs">
            <tbody>
              <tr>
                <td>
                  {mileage} km
                  <img className="c-product-tile__nap" src="/media/assets/nap-logo.png" alt="nap logo" />
                </td>
                <td>{amountPk} PK ({amountKw} kW)</td>
              </tr>
              <tr>
                <td>{constructionDate}</td>
                <td>€ {purchasePrice},-</td>
              </tr>
              <tr>
                <td>{fuel}</td>
                <td>{transmission}</td>
              </tr>
            </tbody>
          </table>
          <div className="c-product-tile__location">{location}</div>
          <div className="c-product-tile__footer">
            <div className="c-product-tile__lease-price">€{leasePrice} p/m</div>
            <Button
              icon="arrow-right"
              iconRight
              primary
              href={link}
            >
              Bekijk deal
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default ProductTile
