import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Link from '../link'

class BlogArticle extends Component {
  onRedirect = () => {
    const {link} = this.props
    if (window) {
      window.location = link
    }
  }

  render () {
    const {image, title, date, text, link} = this.props
    return (
      <div className="c-blog-article">
        <div
          className="c-blog-article__image-container"
          style={{backgroundImage: `url(${image}`}}
          onClick={this.onRedirect}
        />
        <div className="c-blog-article__content">
          <p className="c-blog-article__title" onClick={this.onRedirect}>
            {title}
          </p>
          <p className="c-blog-article__date">{date}</p>
          <p className="c-blog-article__text" dangerouslySetInnerHTML={{__html: text}} />
        </div>
        <Link
          href={link}
          icon="arrow-right"
        >
          Lees verder
        </Link>
      </div>
    )
  }
}

BlogArticle.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string
}

export default BlogArticle
