// IE polyfills
import 'es6-promise/auto'
import 'mdn-polyfills/Object.entries' // 166 bytes
import 'babel-polyfill'
import 'mdn-polyfills/NodeList.prototype.forEach'
import React from 'react'
import ReactDOM from 'react-dom'
import TopBar from 'components/top-bar'
import Calculator from 'components/calculation-block'
import Block from 'components/block'
import KvkField from 'components/kvk-field'
import FeaturedCategory from './components/featured-category'
import HelpSection from 'sections/help-section'
import UspSection from 'sections/usp-section'
import ReviewSection from 'sections/review-section'
import SearchSection from 'sections/search-section'
import BlogSection from 'sections/blog-section'
import ProductTile from 'components/product-tile'

import AskAQuestion from 'forms/ask-a-question'
import CallMe from 'forms/call-me'
import DownloadBrochure from 'forms/download-brochure'
import GetAQuote from 'forms/get-a-quote'
import GetCalculatorQuote from 'forms/get-calculator-quote'
import TipAFriend from 'forms/tip-a-friend'
import Newsletter from 'forms/newsletter'
import ContactUs from 'forms/contact-us'

const widgets = {
  'top-bar': TopBar,
  'calculator': Calculator,
  'block': Block,
  'help-section': HelpSection,
  'usp-section': UspSection,
  'review-section': ReviewSection,
  'search-bar': SearchSection,
  'blog-section': BlogSection,
  'product-tile': ProductTile,
  'featured-category': FeaturedCategory,
  'form-ask-a-question': AskAQuestion,
  'form-call-me': CallMe,
  'form-download-brochure': DownloadBrochure,
  'form-get-a-quote': GetAQuote,
  'form-get-calculator-quote': GetCalculatorQuote,
  'form-tip-a-friend': TipAFriend,
  'form-newsletter': Newsletter,
  'form-contact-us': ContactUs
}


const insertComponent = (domElement, component, props) => {
  ReactDOM.render(
    React.createElement(component, props),
    domElement
  )
}

Object.entries(widgets).forEach(([id, component]) => {
  const domElements = document.querySelectorAll(`[data-react-component="${id}"]`)
  domElements.forEach(domElement => {
    if (!domElement) {
      return
    }
    let props
    try {
      props = domElement.dataset.reactProps && JSON.parse(domElement.dataset.reactProps)
    } catch(e){
      console.log(`parse error for ${id}`, e, domElement.dataset.reactProps)
      props = {}
    }
    insertComponent(domElement, component, props)
  })
})

function serialize(href, obj) {
  let sep = '?';
  if (href.indexOf('?') > -1) {
    sep = '&';
  }
  return sep + Object.keys(obj).reduce(function (a, k) {
    a.push(k + '=' + encodeURIComponent(obj[k]));
    return a
  }, []).join('&')
}

const onWindowLoad = window.onload || (() => {})
window.addCurrentConfigListenerInitialized = false
window.onload = () => {
  if (!window.addCurrentConfigListenerInitialized) {
    window.addCurrentConfigListenerInitialized = true
    onWindowLoad()
    if (window.currentConfig) {
      document.body.addEventListener('click', (event) => {
        let { target } = event
        target = target.closest('.addCurrentConfig')
        if (target) {
          target.href = target.href + serialize(target.href, window.currentConfig)
        }
      })
    }
  }
}
