import React from 'react'
import PropTypes from 'prop-types'

class InfoCircle extends React.Component {
  static propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string
  }
  static defaultProps = {
    icon: 'info-circle'
  }
  state = {
    active: false
  }

  componentDidMount () {
    document.addEventListener('click', this.onClick)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.onClick)
  }

  onClick = (e) => {
    if (!(this.node && this.node.contains(e.target))) {
      this.onClickOutside()
    }
  }

  onClickOutside = () => {
    this.setState({
      active: false
    })
  }

  setRef = (node) => {
    this.node = node
  }

  toggleText = () => {
    this.setState({
      active: !this.state.active
    })

  }

  render () {
    const { icon, text } = this.props
    const { active } = this.state
    const iconClassName = `c-info-circle__icon flicon-${icon}`
    return (
      <div className="c-info-circle" ref={this.setRef}>
        <span className={iconClassName} onClick={this.toggleText}></span>
        {Boolean(active) && (
          <div className="c-info-circle__text">
            {text}
          </div>
        )}
      </div>
    )
  }
}

export default InfoCircle
