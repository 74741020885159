import React from 'react'
import * as API from 'api/magento'
import transformFormValues from 'utils/transform-form-values'

const withPostForm = (Component) => (
  class WithPostForm extends React.Component {
    state = {
      status: '',
      errorMessage: ''
    }

    render () {
      return <Component {...this.props}
                        postForm={this.postForm}
                        postStatus={this.state.status}
                        errorMessage={this.state.errorMessage} />
    }

    postForm = (endpoint, data, successUrl) => new Promise((resolve, reject) => {
      data = transformFormValues(data)

      this.setState({
        status: 'loading'
      })

      API.postMagentoForm(endpoint, data)
        .then(() => {
          this.setState({
            status: 'success'
          })
          window.location.href = successUrl

          resolve()
        })
        .catch((error) => {
          this.setState({
            status: 'fail',
            errorMessage: error.response.data.message
          })

          reject(error)
        })
    })
  }
)

export default withPostForm
