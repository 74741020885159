import React from 'react'
import PropTypes from 'prop-types'
import Button from '../button'

const FeaturedCategory = ({imgUrl, title, description, lowestLeasePrice, link}) => (
  <div className="c-featured-category">
    {imgUrl && (
    <a href={link} className="c-featured-category__header">
      <img className="c-featured-category__image" src={imgUrl} alt={title}/>
    </a>
    )}
    <div className="c-featured-category__body">
      <a href={link} className="c-featured-category__body-header">
        <h3 className="c-featured-category__title">{title}</h3>
      </a>
      <div className="c-featured-category__price">
        <a href={link}>
          Vanaf €{lowestLeasePrice} p/m
          <div className="c-featured-category__price-skew">
            <svg>
              <path d="M0,0 L8.10400391,0 C10.3131429,0 12.1040039,2.4701531 12.1040039,5.51724138 C12.1040039,5.94219862 12.0684086,6.36573304 11.9979259,6.77942063 L8.62307217,26.5876273 L7.78784403,31.4898757 C6.93836636,36.4757597 3.71323912,40 0,40 L0,0 Z" id="Rectangle"></path>
            </svg>
          </div>
        </a>
      </div>
      <div dangerouslySetInnerHTML={{__html: description}} />
    </div>
    <div className="c-featured-category__footer">
      <Button
        icon="arrow-right"
        iconRight
        primary
        href={link}
      >
        Bekijk het aanbod
      </Button>
    </div>
  </div>
)

FeaturedCategory.propTypes = {
  imgUrl: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  lowestLeasePrice: PropTypes.number,
  link: PropTypes.string
}

export default FeaturedCategory