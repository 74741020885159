import React from 'react'
import ReviewBlock from '../../components/review-block'
import AdviceBlock from '../../components/advice-block'
import Section from '../../components/section'
import PropTypes from 'prop-types'

const ReviewSection = ({quote, author, company, feedbackUsers, feedbackGrade}) => (
  <Section hasNegativeMarginBottom>
    <div className='row'>
      <div className='col-md-6 hidden-md-down'>
        <ReviewBlock
          quote={quote}
          author={author}
          company={company}
          feedbackUsers={feedbackUsers}
          feedbackGrade={feedbackGrade}
        />
      </div>
      <div className='col-md-6'>
        <AdviceBlock />
      </div>
    </div>
  </Section>
)

ReviewSection.propTypes = {
  quote: PropTypes.string,
  author: PropTypes.string,
  company: PropTypes.string,
  feedbackUsers: PropTypes.string,
  feedbackGrade: PropTypes.string
}



export default ReviewSection