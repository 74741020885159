import React, { Component } from 'react'
import Review from '../review'
import FC from '../feedbackcompany'
import Block from '../block'
import PropTypes from 'prop-types'

const ReviewBlock = ({quote, author, company, feedbackGrade, feedbackUsers}) => (
  <div className={'c-review-block'}>
    <Block
      direction='vertical'
      hasHeader
      headerTitle="Ondernemers aan het woord"
      hasShadow
    >
      <Review
        quote={quote}
        author={author}
        company={company}
      />
      <FC users={feedbackUsers} grade={feedbackGrade} />
    </Block>
  </div>
)

ReviewBlock.propTypes = {
  quote: PropTypes.string,
  author: PropTypes.string,
  company: PropTypes.string,
  feedbackUsers: PropTypes.string,
  feedbackGrade: PropTypes.string
}

export default ReviewBlock;
