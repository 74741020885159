import React from 'react'
import PropTypes from 'prop-types'
import Section from 'components/section'
import BlogArticle from 'components/blog-article'

const BlogSection = ({posts}) => (
  <Section isSmall noSkew>
    <div className="c-blog-section">
      <div id="owl-blogarticles">
        {posts.map((post, index) => {
          const parsedPost = JSON.parse(post)
          return (
            <BlogArticle
              image={parsedPost.image}
              title={parsedPost.title}
              date={parsedPost.creationDate}
              text={parsedPost.text}
              link={parsedPost.link}
              key={index}
            />
          )})}
      </div>
    </div>
  </Section>
)

BlogSection.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  creationDate: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string
}

export default BlogSection