import React from 'react'
import PropTypes from 'prop-types'
import Classnames from 'classnames'

class Button extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    href: PropTypes.string,
    primary: PropTypes.bool,
    icon: PropTypes.string,
    iconRight: PropTypes.bool,
    iconLeft: PropTypes.bool,
    alternative: PropTypes.bool,
    widthAuto: PropTypes.bool,
    isDisabled: PropTypes.bool
  }

  static defaultProps = {}
  state = {}

  render () {
    const { children, href, primary, icon, iconRight, iconLeft, widthAuto, fullWidth, alternative, isDisabled} = this.props
    const className = Classnames('c-button', {
      'c-button--primary': primary,
      'c-button--icon-right': iconRight,
      'c-button--icon-left': iconLeft,
      'c-button--alternative' : alternative,
      'c-button--width-auto': widthAuto,
      'c-button--full-width': fullWidth,
      'c-button--is-disabled': isDisabled
    })
    const iconClass = Classnames('c-button__icon flicon-' + icon, {
      'c-button__icon--right': iconRight,
      'c-button__icon--left': iconLeft
    })

    return (
      <a className={className} href={!isDisabled && href}>
        {icon && <i className={iconClass}></i>}
        {children}
      </a>

    )
  }
}

export default Button
