import React, {Component} from 'react'
import {USP} from 'components/usp'
import Link from '../link'

class AdviceBlock extends Component {
  render () {
    return (
      <div className="c-advice-block">
        <h1 className="t-container__title c-advice-block__title">FinancialLease.nl is...</h1>
        <h2 className="t-container__title t-container__title--small c-advice-block__subtitle">
          Flexibel leasen, fiscaal voordelig en direct eigenaar.
        </h2>
        <p className="c-advice-block__content">
          Wij bieden een passende Financial Lease oplossing voor jouw situatie zodat jij je kan focussen op wat echt belangrijk is: jouw onderneming.
        </p>
        <USP>
            Auto kopen <span className="bold">zonder</span> in een keer te betalen
        </USP>
        <USP>
            <span className="bold">Fiscaal voordelig: </span>volledige renteaftrek, en meer
        </USP>
        <USP>
            Behoud van <span className="bold">vermogen</span> binnen de onderneming
        </USP>
        <USP>
            Vanaf het eerste moment ben jij <span className="bold">eigenaar</span> van de auto
        </USP>
        <USP>
            Starter of zzp'er? Acceptatie <span className="bold">zonder jaarcijfers</span> is mogelijk
        </USP>
      </div>
    );
  }
}

export default AdviceBlock;
