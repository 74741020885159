import React from 'react';
import axios from 'axios';

import { COOKIES } from 'utils/constants'
import { isCookieSet, setCookie, getIpAddress, isIpExcluded } from 'utils/helpers'

let timeout = null

const withCalculationProps = (WrappedComponent) => {
  class HOC extends React.Component {
    static defaultProps = {
      defaultRunningTime: 24
    }

    state = {
      monthlyPrice: this.props.monthlyPrice,
      carSalesPrice: this.props.carSalesPrice,
      leasePrice: this.props.leasePrice,
      closingPrice: this.props.closingPrice,
      runTime: this.props.defaultRunningTime,
      productId: this.props.productId,
      storeId: this.props.storeId
    }

    static getDerivedStateFromProps (props, state) {
      if (isCookieSet(COOKIES.QUOTE_IS_BLOCKED)) {
        state = {
          ...state,
          isBlocked: true,
          error: 'Het is niet mogelijk om een tweede aanvraag in te dienen. Wij nemen zo snel mogelijk contact met je op om jouw aanvraag te bespreken. Tijdens dit gesprek kun je aangeven dat je interesse hebt in verschillende auto’s.'
        }
      }

      return state
    }

    fetchNewMonthlyPrice = (state) => {
      const url = this.props.url;

      axios({
        method: 'POST',
        url: url,
        headers: {'Content-Type': 'application/json'},
        data: JSON.stringify({
          productId: state.productId,
          storeId: state.storeId,
          runTime: state.runTime,
          closingPrice: state.closingPrice || 0,
          salePrice: state.carSalesPrice,
          leasePrice: state.leasePrice
        })
      })
        .then(this._updateWindowValues)
        .then((response) => {
            if(response.data[0] === "error"){
              this.setState({error: response.data[1]})
            }else{
              this.setState({monthlyPrice: response.data[1], error: '', loading: false})
            }})
        .catch((error) => this.setState({error: error.response.data.message}));
    }

    getNewMonthlyPrice = (event) => {
      const key = event.target.name;
      const value = event.target.value;
      const state = {
        ...this.state,
        loading: true,
        [key]: value
      };

      this.setState(state);

      if(timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout((event) => {
        this.fetchNewMonthlyPrice(state);
      }, 400)
    }

    _updateWindowValues = (response) => {
      try {
        const [, monthlyPrice] = response.data;
        const {leasePrice, runTime, closingPrice} = this.state;
        const {currentConfig} = window;

        window.currentConfig = {
          ...currentConfig,
          leasePrice,
          monthlyPrice,
          runTime,
          closingPrice
        };
      } catch (e) {
        // nobody cares...
        console.error(e);
      }
      return response;
    }
    render () {
      return (
        <WrappedComponent
          getNewMonthlyPrice={this.getNewMonthlyPrice}
          _updateWindowValues={this._updateWindowValues}
          state={this.state}
          {...this.props}
        />
      );
    }
  }
  return HOC;
};

export default withCalculationProps;
