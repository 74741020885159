import React from 'react'
import PropTypes from 'prop-types'
import Link from '../link'

class HelpBlock extends React.Component {
  static propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
    url: PropTypes.string
  }

  render () {
    const { icon, title, text, url, link } = this.props

    return (
      <div className="c-help-block">
        <span className="c-help-block__icon"><i className={`flicon ${icon}`}/></span>
        <p className="c-help-block__title">{title}</p>
        <p className="c-help-block__text">{text}</p>
        <Link href={url} icon='arrow-right'>{link}</Link>
      </div>
    )
  }
}

export default HelpBlock