import { formatDate } from 'utils/helpers'

export default function transformFormValues (values) {
  const {
    dateOfBirth,
    firstRegistration,
    type,
    licensePlate
  } = values

  return {
    ...values,
    dateOfBirth: formatDate(dateOfBirth),
    firstRegistration: formatDate(firstRegistration),
    type: type ? type.substring(0, 100) : null,
    licensePlate: licensePlate ? licensePlate.replace(/[-]/g, '') : null
  }
}
