import React from 'react'
import PropTypes from 'prop-types'

import Section from '../../components/section'
import UspBlock from '../../components/usp-block'
import Button from '../../components/button'

const UspSection = ({picture, title, subtitle, buttonLink, buttonText}) => (
  <Section type='primary' isSmall>
    <UspBlock image={picture}>
      <h2>{title}</h2>
      <p>{subtitle}</p>
      <Button
        iconRight
        widthAuto
        primary
        icon='arrow-right'
        href={buttonLink}
      >
        {buttonText}
      </Button>
    </UspBlock>
  </Section>
)

UspSection.propTypes = {
  picture: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string
}

export default UspSection