import React from 'react'
import PropTypes from 'prop-types'
import memoizeOne from 'memoize-one'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import withPostForm from 'containers/with-post-form'

import FormInput from 'components/form-input'
import Checkbox from 'components/checkbox'
import FormikInput from 'components/formik-input'
import FormikCheckbox from 'components/formik-checkbox'

const formValidation = Yup.object().shape({
  emailAddress: Yup.string()
    .email("Voer een geldig emailadres in")
    .required('Dit veld is verplicht.'),
  lastName: Yup.string()
    .required('Dit veld is verplicht'),
  privacyStatementOptin: Yup.bool()
    .test('privacyStatementOptin', 'Accepteer dit veld om verder te kunnen gaan.', value => value === true)
})

class DownloadBrochure extends React.Component {
  static propTypes = {
    hiddenFields: PropTypes.object,
    endpoint: PropTypes.string,
    successUrl: PropTypes.string,
    postStatus: PropTypes.string,
    postForm: PropTypes.func
  }

  static defaultProps = {
    postForm: () => {}
  }

  handleSubmit = (values) => {
    const { postForm, endpoint, successUrl } = this.props
    postForm(endpoint, {
      emailAddress: values.emailAddress,
      privacyOptIn: values.privacyStatementOptin,
      sku: values.sku,
      brand: values.brand,
      model: values.model,
      type: values.type,
      licensePlate: values.licensePlate,
      url: values.url,
      hexonClientId: values.hexonClientId,
      initials: values.initials,
      firstName: values.firstName,
      infix: values.infix,
      lastName: values.lastName,
      newsletterOptIn: values.newsletterOptIn,
      gaConnectorData: typeof gaconnector !== 'undefined' ? gaconnector.getCookieValues() : null
    }, successUrl)
  }

  getInitialValues = memoizeOne(() => {
    return {
      emailAddress: '',
      initials: '',
      firstName: '',
      infix: '',
      lastName: '',
      newsletterOptIn: false,
      privacyStatementOptin: false,
      licensePlate: '',
      hexonClientId: '',
      gaConnectorData: typeof gaconnector !== 'undefined' ? gaconnector.getCookieValues() : null,
      ...this.props
    }
  })

  render () {
    return (
      <Formik
        initialValues={this.getInitialValues()}
        onSubmit={this.handleSubmit}
        validationSchema={formValidation}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormInput label="E-mail" required>
              <Field name="emailAddress" component={FormikInput} type="email" />
            </FormInput>
            <FormInput label="Voorletters">
              <Field name="initials" component={FormikInput} />
            </FormInput>
            <FormInput label="Voornaam">
              <Field name="firstName" component={FormikInput} />
            </FormInput>
            <FormInput label="Tussenvoegsel">
              <Field name="infix" component={FormikInput} />
            </FormInput>
            <FormInput label="Achternaam" required>
              <Field name="lastName" component={FormikInput} />
            </FormInput>
            <FormInput>
              <Checkbox
                  component={FormikCheckbox}
                  onChange={e => setFieldValue('newsletterOptIn', !values.newsletterOptIn)}
                  name="newsletterOptIn"
                  id="newsletterOptIn"
                  label="Ja, ik ontvang graag e-mails met hierin aanbiedingen en informatie over Financial Lease"
              />
            </FormInput>
            <FormInput>
              <Field
                name="privacyStatementOptin"
                component={FormikCheckbox}
                label="Ik ga ermee akkoord dat Financial Lease mijn gegevens gebruikt, in overeenstemming met het privacybeleid"
                onChange={e => setFieldValue('privacyStatementOptin', !values.privacyStatementOptin)}
                isRequired
              />
              <a href="/privacyverklaring" className="c-link c-link--with-padding-left" target="_blank">Lees hier het privacybeleid</a>
            </FormInput>

            <button
              type="submit"
              className="c-button c-button--primary c-button--width-auto"
              disabled={this.props.postStatus === 'loading'}
            >
              Verzenden
            </button>
          </Form>
        )}
      </Formik>
    )
  }
}

export default withPostForm(DownloadBrochure)
