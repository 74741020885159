import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

class Section extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    isSmall: PropTypes.bool,
    isSmallTop: PropTypes.bool,
    isSmallBottom: PropTypes.bool,
    extraClass: PropTypes.string,
    noSkew: PropTypes.bool,
    hasNegativeMarginBottom: PropTypes.bool,
    hasBigNegativeMarginBottom: PropTypes.bool,
    title: PropTypes.string
  }

  static defaultProps = {}
  state = {}

  render () {
    const {
      children,
      type,
      isSmall,
      isSmallTop,
      isSmallBottom,
      extraClass,
      noSkew,
      hasQuote,
      hasNegativeMarginBottom,
      hasBigNegativeMarginBottom,
      title
    } = this.props

    return (
      <section className={ClassNames(`c-section c-section--${type}`, {
        'c-section--small': isSmall,
        'c-section--small-top': isSmallTop,
        'c-section--small-bottom': isSmallBottom,
        'c-section--has-quote': hasQuote,
        'c-section--negative-margin-bottom': hasNegativeMarginBottom,
        'c-section--big-negative-margin-bottom': hasBigNegativeMarginBottom
      }, extraClass)}>
        {!noSkew && (<div className={`c-section__skew c-section__skew--${type}`}>
          <svg preserveAspectRatio="none" viewBox="0 0 100 100">
            <polygon points="100,100 100,0 0,100"></polygon>
          </svg>
        </div>)}
        <div className="container">
          {title && (
            <h2 className="c-section__title">{title}</h2>
          )}
          {children}
        </div>
      </section>
    )
  }
}

export default Section
