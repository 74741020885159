import Classnames from "classnames";
import Proptypes from "prop-types";
import React from "react";

export const Section = ({children, className}) => (
  <div className={Classnames('c-calculation-block__item', className)}>
    {children}
  </div>
);
Section.propTypes = {
  children: Proptypes.node,
  className: Proptypes.string
};