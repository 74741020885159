import axios from "axios";

export default async function fetchKvk(q) {
  const {data: {resultatenHR}} = await axios({
    method: 'GET',
    url: 'https://zoeken.kvk.nl/Address.ashx?site=handelsregister&partialfields=&q=' + q,
  })

  return resultatenHR.map(({dossiernummer, handelsnaam, straat, huisnummer, plaats}) => ({
    kvkNumber: dossiernummer,
    companyName: handelsnaam,
    street: straat,
    houseNumber: huisnummer,
    city: plaats
  }))
}
