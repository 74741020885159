export const ENGINE_POWER = {
  HP: 'PK',
  KW: 'kW'
}

export const FILTERS = {
  VEHICLE_TYPE: 'vehicle_type',                                   // Type auto
  TAX_MARGIN: 'btw_marge',                                        // BTW Aftrekbaar
  LACQUER_TYPE: 'lacquer_type',                                   // Metallic lak
  HAS_WARRANTY: 'has_warranty',                                   // Garantie
  TRANSMISSION: 'transmission',                                   // Transmissie
  CAR_EXTENDED: 'car_extended',                                   // Lengte
  CAR_RAISED: 'car_raised',                                       // Hoogte
  BODYWORK: 'bodywork',                                           // Carosserie
  FUEL_TYPE: 'fuel_type',                                         // Brandstof
  NUMBER_OF_DOORS: 'number_doors',                                // Aantal deuren
  COLOR: 'color',                                                 // Exterieur Kleur
  LINING: 'lining',                                               // Type bekleding
  INTERIOR_COLOR: 'interior_color',                               // Kleur bekleding
  NUMBER_OF_CYLINDERS: 'number_cylinders',                        // Cylinders
  ADDITIONAL_TAX_PERCENTAGE: 'additional_tax_pct',                // Bijtelling
  CAR_NEW: 'car_new',                                             // Nieuw
  BUILDYEAR: 'buildyear',                                         // Bouwjaar
  MILEAGE: 'mileage',                                             // Kilometerstand
  DEFAULT_LEASE_PRICE: 'price.EUR.default',                       // Leaseprijs
  DEFAULT_SALES_PRICE: 'sales_cost',                              // Verkoopprijs
  MONTHLY_TAX: 'monthly_wegenbelasting',                          // Wegenbelasting
  CAR_ACTION_RADIUS: 'car_action_radius',                         // Actieradius
  CYLINDER_VOLUME: 'cylinder_volume',                             // Motorinhoud
  MAX_DRAWWEIGHT_UNRESTRAINED: 'max_drawweight_unrestrained',     // Trekgewicht
  PAYLOAD: 'payload',                                             // Laadgewicht
  MASS: 'mass',                                                   // Lediggewicht
  CAR_TOP_SPEED: 'car_top_speed',                                 // Topsnelheid
  ACCELERATION: 'acceleration',                                   // Acceleratie
  CONSUMPTION_AVERAGE: 'consumption_average',                     // Gem. Verbruik
  ENGINE_POWER_HP: 'engine_power_pk',                             // Vermogen (PK)
  ENGINE_POWER_KW: 'engine_power_kw',                             // Vermogen (kW)
  BRAND: 'brand',                                                 // Merk
  MODEL: 'model',                                                 // Model
  CAR_CABINE: 'car_cabine',                                       // Cabine
  POWER: 'vermogen',                                              // Vermogen (combi)
  DATE_DELIVERY: 'date_delivery'                                  // datum binnenkomst
}

export const REFINEMENTS = {
  ALL: 'Alle'
}

export const POPULAR_CARS = [
  'audi',
  'bmw',
  'ford',
  'mercedes-benz',
  'opel',
  'renault',
  'volkswagen'
]

export const FILTER_TYPE = {
  SINGLE_SELECT: 'singleselect',
  MULTISELECT: 'multiselect',
  MULTISELECT_SEARCH: 'multiselect_search',
  RANGE: 'range',
  DOUBLE_INPUT: 'double_input'
}

export const COOKIES = {
  QUOTE_IS_BLOCKED: 'quote_is_blocked'
}

export const EXCLUDED_IPS = ['185.134.216.226', '94.215.114.205']
