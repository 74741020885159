import React from "react"
import PropTypes from 'prop-types'
import { InstantSearch, connectSearchBox, connectStats, connectMenu, connectRange } from 'react-instantsearch-dom'
import {FILTERS} from "../../utils/constants"

import Button from '../../components/button'
import Input from '../input'

const SearchBox = ({ currentRefinement, refine }) => (
  <Input
    type="text"
    value={currentRefinement}
    onChange={e => refine(e.target.value)}
    placeholder="Zoek op trefwoorden"
    name="searchbar-input"
    id='searchbox'
  />
)
const ConnectedSearchBox = connectSearchBox(SearchBox)

const Stats = ({nbHits}) => nbHits
const ConnectedStats = connectStats(Stats)

function compareItems(a, b) {
    if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
    if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
    return 0;
}

const MenuSelect = ({items, currentRefinement, refine, attribute, translations, disabled}) => {
  const MINIMUM_CARS_IN_BRAND = 2
  const MINIMUM_CARS_IN_MODEL = 4

  if (attribute === FILTERS.BRAND || attribute === FILTERS.MODEL) {
    items.sort(compareItems);
  }

  if (attribute === FILTERS.BRAND) {
    items = items.filter(item => item.count >= MINIMUM_CARS_IN_BRAND)
  }

  if (attribute === FILTERS.MODEL) {
    items = items.filter(item => item.count >= MINIMUM_CARS_IN_MODEL)
  }

  return (
    <select
      className="c-select"
      name={attribute}
      value={currentRefinement || ''}
      onChange={event => refine(event.currentTarget.value)}
      disabled={disabled}
    >
      <option value="">{translations.seeAllOption}</option>
      {items.map(item => (
        <option
          key={item.label}
          value={item.isRefined ? currentRefinement : item.value}
        >
          {item.label}
        </option>
      ))}
    </select>
  );
}
const CustomMenuSelect = connectMenu(MenuSelect)

const RangeMenuSelect = ({ currentRefinement, refine, attribute, translations }) => {
  const items = [
    { min: 1, max: 99 },
    { min: 100, max: 199 },
    { min: 200, max: 299 },
    { min: 300, max: 399 },
    { min: 400, max: 499 },
    { min: 500, max: 599 },
    { min: 600, max: 699 },
    { min: 700, max: 799 },
    { min: 800, max: 899 },
    { min: 900, max: 999 },
    { min: 1000 }
  ]

  return (
    <select
      className="c-select"
      name={attribute}
      value={JSON.stringify(currentRefinement) || ''}
      onChange={event => refine(event.currentTarget.value !== '' ? JSON.parse(event.currentTarget.value) : '')}
    >
      <option value=''>{translations.seeAllOption}</option>
      {items.map(item => (
        <option
          key={item.min}
          value={item.max ? JSON.stringify({min: item.min, max: item.max || ''}) : JSON.stringify({min: item.min, max: 10000})}
        >
          €{item.min} {item.max ? `- €${item.max}` : '>'} p/m
        </option>
      ))}
    </select>
  )
}
const CustomRangeMenuSelect = connectRange(RangeMenuSelect)

class Search extends React.Component {
  state = {
    searchState: {}
  }

  customButton = ({nbHits}) => (
    <Button
      href={this.createURL()}
      icon="search"
      iconLeft
      isDisabled={nbHits === 0}
    >
      Zoeken
    </Button>
  )

  onSearchStateChange = searchState => {
    this.setState({searchState})
  }

  createURL = () => {
    let url = 'aanbod'
    const search = this.state.searchState

    search.menu && search.menu.brand ? url += `/${search.menu.brand.toLowerCase()}` : ''
    search.menu && search.menu.brand && search.menu.model ? url += `/${search.menu.model.toLowerCase()}` : ''

    search.query ? url += `?q=${encodeURIComponent(search.query)}` : ''

    const range = search.range
    if(range) {
      const key = Object.keys(range)[0]
      const values = Object.values(range)[0]
      url += `${search.query ? '&' : '?'}${key}=`
      url += values && values.min && `${values.min}%3A`
      url += values && values.max && `${values.max}`
    }

    url = url.replace(/ /g,'-')
    return url
  }

  onKeyUp = (e) => {
    if(e.key === "Enter") {
      window.location.replace(this.createURL())
    }
  }

  render () {
    const { algoliaAppId, algoliaIndice, algoliaApiKey, algoliaFacets } = this.props
    const ConnectedButton = connectStats(this.customButton)

    return (
      <InstantSearch
        appId={algoliaAppId}
        apiKey={algoliaApiKey}
        indexName={algoliaIndice}
        searchState={this.state.searchState}
        onSearchStateChange={this.onSearchStateChange}
      >
        <div className="c-searchbar">
          <p className="c-searchbar__title"> Zoek in onze <ConnectedStats /> voertuigen</p>
          <div className="c-searchbar__content">
            <div className="c-searchbar__input" onKeyUp={this.onKeyUp}>
              <ConnectedSearchBox />
            </div>
            <div className="c-searchbar__select">
              <div className="c-select-container">
                <CustomMenuSelect
                  attribute={algoliaFacets.facetOne.item}
                  translations={{seeAllOption: algoliaFacets.facetOne.name}}
                  limit={Number.POSITIVE_INFINITY}
                  disabled={false}
                />
              </div>
            </div>
            <div className="c-searchbar__select">
              <div className="c-select-container">
                <CustomMenuSelect
                  attribute={algoliaFacets.facetTwo.item}
                  translations={{seeAllOption: algoliaFacets.facetTwo.name}}
                  limit={Number.POSITIVE_INFINITY}
                  disabled={!Boolean(this.state.searchState.menu && this.state.searchState.menu.brand !== "")}
                />
              </div>
            </div>
            <div className="c-searchbar__select">
              <div className="c-select-container">
                <CustomRangeMenuSelect
                  attribute={algoliaFacets.facetThree.item}
                  translations={{seeAllOption: algoliaFacets.facetThree.name}}
                  min={1}
                  max={100000}
                />
              </div>
            </div>
            <div className="c-searchbar__button">
              <ConnectedButton />
            </div>
          </div>
        </div>
      </InstantSearch>
    )
  }
}

Search.propTypes = {
  algoliaAppId: PropTypes.string,
  algoliaIndice: PropTypes.string,
  algoliaApiKey: PropTypes.string,
  algoliaSearchUrl: PropTypes.string,
  algoliaFacets: PropTypes.object
}

export default Search