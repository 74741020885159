import React from 'react'

export const USP = (props) => (
  <div className="c-usp">
    <i className='flicon-check' />
    <span className='c-usp__text'>
      {props.children}
    </span>
  </div>
)
