import React from 'react'
import PropTypes from 'prop-types'

import withPostForm from 'containers/with-post-form'

import FormStep from 'components/form-step'
import FormInput from 'components/form-input'
import Checkbox from 'components/checkbox'
import memoizeOne from "memoize-one";
import {Formik, Form, Field} from "formik";

import FormikInput from "../components/formik-input";
import FormikRadio from "../components/formik-radio";
import FormikTextArea from "../components/formik-textarea"
import FormikCheckbox from "../components/formik-checkbox";
import * as Yup from "yup";
import FormikKvk from "../components/formik-kvk";
import FormikDate from "../components/formik-date";

class GetCalculatorQuote extends React.Component {
  static propTypes = {
    hiddenFields: PropTypes.object,
    endpoint: PropTypes.string,
    successUrl: PropTypes.string,
    postStatus: PropTypes.string,
    postForm: PropTypes.func,
    errorMessage: PropTypes.string
  }

  static defaultProps = {
    postForm: () => {}
  }

  state = {
    currentStep: 1,
    totalSteps: 3,
    dateOfBirth: ''
  }

  urlParams = new URLSearchParams(window.location.search)

  renderNavigationButtons = ({state, props}) => {
    const touchedLength = Object.values(props.touched).length
    const step = state.currentStep

    const possibleFilledInItems = ['brand', 'model', 'buildYear', 'vehicleType']

    const minimumFilledInItemsToContinue = {1: 4, 2: 8, 3: 11}

    let prefilledItems = 0
    possibleFilledInItems.forEach(item => props.values[item] && !props.touched[item] && prefilledItems++)

    const isDisabled = (!props.isValid || this.props.postStatus) || minimumFilledInItemsToContinue[step] > touchedLength + prefilledItems
    const back = state.currentStep !== 1
    const isLastStep = state.currentStep === state.totalSteps

    return (
      <div className="row">
        <div className="col-12 col-md-7 u-flex u-space-between">
          {back && (
            <button
              type="button"
              className='c-link c-link--icon'
              onClick={this.onClickPrevButton}
            >
              <i className='c-link__icon flicon-arrow-left'></i> Vorige
            </button>
          )}
          {isLastStep ? (
              <button
                type="button"
                className='c-button c-button--primary c-button--width-auto'
                onClick={() => this.handleSubmit(props)}
              >
                Verzenden
              </button>
            ) : (
            <button
              type="button"
              className='c-button c-button--primary c-button--icon-right c-button--width-auto'
              onClick={() => this.onClickNextButton(props)}
            >
              Volgende <i className='c-button__icon c-button__icon--right flicon-arrow-right'></i>
            </button>
            )}
        </div>
        {this.props.errorMessage && (
          <div className="col-12 col-md-7 c-form__required">
            <br />
            {this.props.errorMessage}
          </div>
        )}
      </div>
    )
  }

  validateRequiredFields = async (props) => {
    const invalidFields = await props.validateForm()
    Object.keys(invalidFields).forEach((field) => {
      props.setFieldTouched(field, true)
    })
    return Boolean(!Object.keys(invalidFields).length)
  }

  onClickNextButton = async (props) => {
    const isValid = await this.validateRequiredFields(props)
    return isValid && this.setState({currentStep: this.state.currentStep + 1})
  }

  onClickPrevButton = () => {
    if (this.state.currentStep > 1) {
      this.setState({currentStep: this.state.currentStep - 1})
    }
  }

  handleSubmit = async (props) => {
    const isValid = await this.validateRequiredFields(props)
    if (!isValid) return
    const values = props.values
    const { postForm, endpoint, successUrl } = this.props
    postForm(endpoint, {
      brand: values.brand,
      model: values.model,
      initials: values.initials,
      buildYear: values.buildYear,
      companyName: values.companyName,
      kvkNumber: values.kvkNumber,
      phoneNumber: values.phoneNumber,
      emailAddress: values.emailAddress,
      salutation: values.salutation,
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth: values.dateOfBirth,
      privacyOptIn: values.privacyOptIn,
      salesPrice: values.salesPrice,
      leasePrice: values.leasePrice,
      runTime: values.runTime,
      closingPrice: values.closingPrice,
      vehicleType: values.vehicleType,
      licensePlate: values.licensePlate,
      monthlyPrice: values.monthlyPrice,
      infix: values.infix,
      comments: values.comments,
      newsletterOptIn: values.newsletterOptIn,
      dealerName: values.dealerName,
      dealerLocation: values.dealerLocation,
      urlCar: values.urlCar,
      hexonClientId: values.hexonClientId,
      gaConnectorData: typeof gaconnector !== 'undefined' ? gaconnector.getCookieValues() : null
    }, successUrl)
  }

  getInitialValue = (key) => {
    return this.urlParams.has(key)
      ? this.urlParams.get(key)
      : this.props[key] ? this.props[key] : ''
  }

  getInitialValues = memoizeOne(() => {
    return {
      ...this.props,
      brand: this.getInitialValue('brand'),
      model: this.getInitialValue('model'),
      initials: this.getInitialValue('initials'),
      buildYear: this.getInitialValue('buildYear'),
      companyName: this.getInitialValue('companyName'),
      kvkNumber: this.getInitialValue('kvkNumber'),
      phoneNumber: this.getInitialValue('phoneNumber'),
      emailAddress: this.getInitialValue('emailAddress'),
      salutation: this.getInitialValue('salutation'),
      firstName: this.getInitialValue('firstName'),
      lastName: this.getInitialValue('lastName'),
      dateOfBirth: this.getInitialValue('dateOfBirth'),
      salesPrice: this.getInitialValue('salesPrice') || this.getInitialValue('salePrice'),
      leasePrice: this.getInitialValue('leasePrice'),
      runTime: this.getInitialValue('runTime'),
      closingPrice: this.getInitialValue('closingPrice'),
      vehicleType: this.getInitialValue('vehicleType'),
      licensePlate: this.getInitialValue('licensePlate'),
      monthlyPrice: this.getInitialValue('monthlyPrice'),
      infix: this.getInitialValue('infix'),
      comments: this.getInitialValue('comments'),
      dealerName: this.getInitialValue('dealerName'),
      dealerLocation: this.getInitialValue('dealerLocation'),
      hexonClientId: this.getInitialValue('hexonClientId'),
      urlCar: this.getInitialValue('urlCar'),
      newsletterOptIn: false,
      privacyOptIn: false,
      gaConnectorData: typeof gaconnector !== 'undefined' ? gaconnector.getCookieValues() : null,
    }
  })

  stepOneValidation = Yup.object().shape({
    vehicleType: Yup.string()
      .required("Dit veld is verplicht."),
    brand: Yup.string()
      .required("Dit veld is verplicht."),
    model: Yup.string()
      .required("Dit veld is verplicht."),
    buildYear: Yup.string()
      .required("Dit veld is verplicht.")
      .matches(/^\d{4}$/, "Controleer het bouwjaar")
  })
  stepTwoValidation = Yup.object().shape({
    companyName: Yup.string()
      .required("Dit veld is verplicht."),
    kvkNumber: Yup.string()
      .required("Dit veld is verplicht.")
      .min(8, "Vul minimaal 8 karakters in"),
    phoneNumber: Yup.string()
      .required('Dit veld is verplicht.')
      .min(8, "Voer een geldig telefoonnummer in")
      .max(13, "Voer een geldig telefoonnummer in"),
    emailAddress: Yup.string()
      .email("Voer een geldig e-mailadres in")
      .matches(/^[A-Za-z0-9!#$%&.'*/=?^_+-`{|}~]+$/, "Het e-mailadres bevat karakters die niet toegestaan zijn")
      .required("Dit veld is verplicht.")
  })
  stepThreeValidation = Yup.object().shape({
    salutation: Yup.string()
        .required("Dit veld is verplicht."),
    initials: Yup.string()
        .required("Dit veld is verplicht."),
    firstName: Yup.string()
        .required("Dit veld is verplicht."),
    lastName: Yup.string()
        .required("Dit veld is verplicht."),
    dateOfBirth: Yup.string()
        .required("Dit veld is verplicht,"),
    privacyOptIn: Yup.bool()
        .test('privacyOptIn', 'Accepteer dit veld om verder te kunnen gaan.', value => value === true)
  })
  formValidation = [this.stepOneValidation, this.stepTwoValidation, this.stepThreeValidation]

  render () {
    const { totalSteps, currentStep, dateOfBirth } = this.state
    const NavigationButtons = this.renderNavigationButtons

    return (
      <Formik
        initialValues={this.getInitialValues()}
        onSubmit={this.handleSubmit}
        validationSchema={this.formValidation[this.state.currentStep - 1]}
      >
        {(props) => (
          <Form>
            <FormStep
              step="1"
              stepTitle="Vraag een gratis offerte aan"
              totalSteps={totalSteps}
              title="Autogegevens"
              isActive={currentStep === 1}
            >
              <FormInput label="Autotype" required>
                <Field name='vehicleType' component={FormikRadio} id='Bedrijfsauto' label='Bedrijfsauto' />
                <Field name='vehicleType' component={FormikRadio} id='Personenauto' label='Personenauto' />
              </FormInput>
              <FormInput label="Merk" required>
                <Field name="brand" component={FormikInput} />
              </FormInput>
              <FormInput label="Model" required>
                <Field name="model" component={FormikInput} />
              </FormInput>
              <FormInput label="Bouwjaar" required>
                <Field name="buildYear" component={FormikInput} />
              </FormInput>
              <FormInput label="Kentekenplaat">
                <Field name="licensePlate" component={FormikInput} />
              </FormInput>
            </FormStep>
            <FormStep
              step="2"
              stepTitle="Vul je gegevens in"
              totalSteps={totalSteps}
              title="Bedrijfsgegevens"
              isActive={currentStep === 2}
            >
              <FormikKvk {...props} />
              <FormInput label="Telefoonnummer" required>
                <Field name="phoneNumber" component={FormikInput} type="tel" />
              </FormInput>
              <FormInput label="E-mail" required>
                <Field name="emailAddress" component={FormikInput} type="email" />
              </FormInput>
            </FormStep>
            <FormStep
              step="3"
              stepTitle="Afronden gratis offerte"
              totalSteps={totalSteps}
              title="Persoonsgegevens"
              isActive={currentStep === 3}
            >
              <FormInput label='Aanhef' required>
                <Field name='salutation' component={FormikRadio} id='male' label='Dhr.'/>
                <Field name='salutation' component={FormikRadio} id='female' label='Mevr.'/>
              </FormInput>
              <FormInput label="Voorletters" required>
                <Field name='initials' component={FormikInput} />
              </FormInput>
              <FormInput label="Voornaam" required>
                <Field name="firstName" component={FormikInput} />
              </FormInput>
              <FormInput label="Tussenvoegsel">
                <Field name='infix' component={FormikInput} />
              </FormInput>
              <FormInput label="Achternaam" required>
                <Field name='lastName' component={FormikInput} />
              </FormInput>
              <FormInput label="Geboortedatum" required>
                <FormikDate
                  dateOfBirth={dateOfBirth}
                  onSetDateOfBirth={date => this.setState({dateOfBirth: date})}
                  onChange={formattedDate => props.setFieldValue('dateOfBirth', formattedDate)}
                />
              </FormInput>
              <FormInput label="Opmerkingen">
                <Field name="comments" component={FormikTextArea} />
              </FormInput>
              <FormInput>
                <Checkbox
                    component={FormikCheckbox}
                    onChange={e => props.setFieldValue('newsletterOptIn', !props.values.newsletterOptIn)}
                    name="newsletterOptIn"
                    id="newsletterOptIn"
                    label="Ja, ik ontvang graag e-mails met hierin aanbiedingen en informatie over Financial Lease"
                />
              </FormInput>
              <FormInput>
                <Field
                    name='privacyOptIn'
                    component={FormikCheckbox}
                    label="Ik ga ermee akkoord dat Financial Lease mijn gegevens gebruikt, in overeenstemming met het <a href='/privacyverklaring' class='c-link' target='_blank'>Privacybeleid</a>"
                    onChange={e => props.setFieldValue('privacyOptIn', !props.values.privacyOptIn)}
                    isRequired={true}
                />
              </FormInput>
            </FormStep>
            <NavigationButtons
              state={this.state}
              props={props}
            />
          </Form>
        )}
      </Formik>
    )
  }
}


export default withPostForm(GetCalculatorQuote)
