import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import memoizeOne from 'memoize-one'

import FormInput from 'components/form-input'
import Checkbox from 'components/checkbox'
import FormikInput from 'components/formik-input'
import FormikCheckbox from 'components/formik-checkbox'
import FormTitle from 'components/form-title'

import withPostForm from 'containers/with-post-form'

const formValidation = Yup.object().shape({
  senderFirstName: Yup.string()
    .required('Dit veld is verplicht.'),
  senderLastName: Yup.string()
    .required('Dit veld is verplicht'),
  senderEmail: Yup.string()
    .email("Voer een geldig emailadres in")
    .required('Dit veld is verplicht.'),
  receiverEmail: Yup.string()
    .email("Voer een geldig emailadres in")
    .required('Dit veld is verplicht.'),
  privacyStatementOptin: Yup.bool()
    .test('privacyStatementOptin', 'Accepteer dit veld om verder te kunnen gaan.', value => value === true)
})

class TipAFriend extends React.Component {
  static propTypes = {
    hiddenFields: PropTypes.object,
    endpoint: PropTypes.string,
    successUrl: PropTypes.string,
    postStatus: PropTypes.string,
    postForm: PropTypes.func
  }

  static defaultProps = {
    postForm: () => {}
  }

  handleSubmit = (values) => {
    const { postForm, endpoint, successUrl } = this.props
    postForm(endpoint, {
      privacyOptIn: values.privacyStatementOptin,
      emailAddress: values.senderEmail,
      receiverEmailAddress: values.receiverEmail,
      brand: values.brand,
      model: values.model,
      type: values.type,
      licensePlate: values.licensePlate,
      url: values.url,
      hexonClientId: values.hexonClientId,
      firstName: values.senderFirstName,
      lastName: values.senderLastName,
      infix: values.senderInfix,
      receiverName: values.receiverName,
      commentForReceiver: values.receiverComment,
      newsletterOptIn: values.newsletterOptIn,
      gaConnectorData: typeof gaconnector !== 'undefined' ? gaconnector.getCookieValues() : null
    }, successUrl)
  }

  getInitialValues = memoizeOne(() => {
    return {
      senderFirstName: '',
      senderInfix: '',
      senderLastName: '',
      senderEmail: '',
      receiverName: '',
      receiverEmail: '',
      receiverComment: '',
      newsletterOptIn: false,
      privacyStatementOptin: false,
      gaConnectorData: typeof gaconnector !== 'undefined' ? gaconnector.getCookieValues() : null,
      ...this.props
    }
  })

  render () {
    return (
      <Formik
        initialValues={this.getInitialValues()}
        onSubmit={this.handleSubmit}
        validationSchema={formValidation}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormTitle title="Mijn gegevens" />
            <FormInput label="Voornaam" required>
              <Field component={FormikInput} name="senderFirstName" />
            </FormInput>
            <FormInput label="Tussenvoegsel">
              <Field component={FormikInput} name="senderInfix" />
            </FormInput>
            <FormInput label="Achternaam" required>
              <Field component={FormikInput} name="senderLastName" />
            </FormInput>
            <FormInput label="E-mail" required>
              <Field name="senderEmail" component={FormikInput} type="email" />
            </FormInput>

            <FormTitle title="Verstuur als tip naar" />
            <FormInput label="Naam ontvanger">
              <Field component={FormikInput} name="receiverName" />
            </FormInput>
            <FormInput label="E-mail ontvanger" required>
              <Field name="receiverEmail" component={FormikInput} type="email" />
            </FormInput>
            <FormInput label="Bericht">
              <Field component={FormikInput} name="receiverComment" />
            </FormInput>
            <FormInput>
              <Checkbox
                  component={FormikCheckbox}
                  onChange={e => setFieldValue('newsletterOptIn', !values.newsletterOptIn)}
                  name="newsletterOptIn"
                  id="newsletterOptIn"
                  label="Ja, ik ontvang graag e-mails met hierin aanbiedingen en informatie over Financial Lease"
              />
            </FormInput>
            <FormInput>
              <Field
                name="privacyStatementOptin"
                component={FormikCheckbox}
                label="Ik ga ermee akkoord dat Financial Lease mijn gegevens gebruikt, in overeenstemming met het privacybeleid"
                onChange={e => setFieldValue('privacyStatementOptin', !values.privacyStatementOptin)}
                isRequired
              />
              <a href="/privacyverklaring" className="c-link c-link--with-padding-left" target="_blank">Lees hier het privacybeleid</a>
            </FormInput>

            <button
              type="submit"
              className="c-button c-button--primary c-button--width-auto"
              disabled={this.props.postStatus === 'loading'}
            >
              Verzenden
            </button>
          </Form>
        )}
      </Formik>
    )
  }
}

export default withPostForm(TipAFriend)
